@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/@cat2/legacy-meta-cat/styles/theming-init";

#cat2-page-top {
  .container-fluid {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

#cat2-main {
  padding-left: 0;
  padding-right: 0;
}

// Remove default padding from the application
#cat2-main-padding {
  padding: 0 !important;

  .col-12:first-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// Style filter bar
.filter-menu {
  .mat-mdc-menu-panel {
    padding: 10px;
    background-color: #E7E7E7 !important;
  }
}

// Overflow of cdk scroll
.cdk-virtual-scroll-content-wrapper {
  overflow-x: hidden;
}

.v-align-middle {
  vertical-align: middle;
}
#cat2-main{
  display: inline !important;
}

::-webkit-scrollbar {
  width: 30px;
  overflow-y: scroll;
  background: grey;
  box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
  background: #5A5A5A;
  border-radius: 10px;
}
.powerbi-report-container {
  height: 90vh;
  margin: 8px auto;
  width: 100%;
}
